import { loadState, LocalStorageKeys } from "@lib/utils"
import { decode } from "shopify-gid"

const useInjectStampedLoyalty = () => {
  function injectScript(apiKey: string, storeUrl: string, rewards = {}) {
    const script = document.createElement("script")
    script.async = true
    script.src = "https://cdn1.stamped.io/files/widget.min.js"
    script.dataset.script = "stamped-loyalty"
    document.head.append(script)
    script.addEventListener("load", () => {
      /*@ts-ignore*/
      const { StampedFn } = window
      if (!StampedFn) {
        console.log("Error initializing StampedFn")
      } else {
        /*@ts-ignore*/
        StampedFn.init({ apiKey, storeUrl })
        /*@ts-ignore*/
        StampedFn.on("stamped:init:starting", function () {
          /*@ts-ignore*/
          StampedFn.initRewards(rewards)
        })
      }
    })
    return script
  }

  const injectStamped = async () => {
    let loginObj = {}
    const customer = loadState(LocalStorageKeys.CUSTOMER)
    if (customer) {
      const authToken = await fetch("/api/getStampedAuthToken", {
        method: "POST",
        body: JSON.stringify({
          email: customer?.email,
          userId: decode(customer?.id).id,
        }),
      })
      if (!authToken.ok) {
        return {}
      }
      const stampedAuthToken = await authToken.text()
      loginObj = {
        customerId: decode(customer?.id).id,
        customerEmail: customer?.email,
        customerFirstName: customer?.firstName,
        customerLastName: customer?.lastName,
        customerTags: "",
        totalOrders: customer?.orders?.edges.length,
        totalSpent: customer?.orders?.edges
          ?.map((edge: any) => Number(edge.node.totalPriceV2.amount))
          ?.reduce((a: any, b: any) => a + b, 0),
        isAcceptMarketing: customer?.acceptsMarketing,
        authToken: stampedAuthToken,
      }
    }

    var _a
    const script =
      (_a = document.querySelector('[data-script="stamped-loyalty"]')) !== null && _a !== void 0
        ? _a
        : injectScript("pubkey-x6x8XHkF25qzkFe082aGK2R76fr6kb", "www.hautehijab.com", loginObj)
    if (loginObj) return loginObj
    return
  }

  return injectStamped
}

export default useInjectStampedLoyalty
