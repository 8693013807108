import { FC, useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { RichText } from "@components/common"
import Cross from "@svg/cross.svg"

import { useSite } from "@hooks/index"
import { LocalStorageKeys, loadState, saveState } from "@lib/utils"

export const AnnouncementBar: FC = () => {
  const site = useSite()
  const timeNow = Date.now()
  const [alreadySeen, setAlreadySeen] = useState(
    loadState(LocalStorageKeys.ANNOUNCEMENT_SEEN_EXPIRY) > timeNow
  )
  const saveAnnouncementSeenExpiry = saveState(LocalStorageKeys.ANNOUNCEMENT_SEEN_EXPIRY)
  const handleAnnouncementClose = () => {
    setAlreadySeen(true)
    // 86400000 is 1 day in ms
    saveAnnouncementSeenExpiry(Date.now() + 86400000)
  }
  const isAnnouncementHidden = site.isAnnouncementHidden
  const announcementsArray = site.announcementsCollection.items
  const amountOfAnnouncements = announcementsArray.length
  const announcementsContainer = useRef<any>()
  let currentIndex = -1 // Starts at -1 because our first iteration will increase currentIndex to 0
  let nextIndex
  const useInterval = () => {
    if (currentIndex >= amountOfAnnouncements - 1) {
      currentIndex = 0
    } else {
      currentIndex += 1
    }
    nextIndex = currentIndex + 1
    if (nextIndex >= amountOfAnnouncements) {
      nextIndex = 0
    }
    announcementsContainer.current?.children[nextIndex]?.classList.add("active")
    announcementsContainer.current?.children[currentIndex]?.classList.remove("active")
  }
  useEffect(() => {
    if (announcementsContainer.current && amountOfAnnouncements > 1) {
      const interval = setInterval(() => {
        useInterval()
      }, 6000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [])

  return (
    <>
      {!isAnnouncementHidden && amountOfAnnouncements > 0 && !alreadySeen && (
        <StyledAnnouncementBar>
          <CloseButton onClick={handleAnnouncementClose}>
            <Cross />
          </CloseButton>
          <AnnouncementsContainer ref={announcementsContainer}>
            {amountOfAnnouncements === 1 ? (
              <NormalWrapper className="active">
                <RichText richText={announcementsArray[0].richText} />
              </NormalWrapper>
            ) : (
              <>
                {announcementsArray.map((content: any, i: any) => {
                  return (
                    <NormalWrapper key={`announcement-${i}`} className={i === 0 ? "active" : ""}>
                      <RichText richText={content.richText} />
                    </NormalWrapper>
                  )
                })}
              </>
            )}
          </AnnouncementsContainer>
        </StyledAnnouncementBar>
      )}
    </>
  )
}

const StyledAnnouncementBar = styled.section`
  height: 3rem;
  padding: 0 4.7rem;
  background: ${({ theme }) => theme.color.beige};
  text-align: center;
  font-family: ${({ theme }) => theme.font.heading};
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  p {
    margin: 0;
  }

  a {
    font-family: inherit;
  }

  button {
    font-family: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-transform: uppercase;
    font-size: inherit;
    cursor: pointer;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    font-size: 1.4rem;
    line-height: 1.68rem;
  }
`
const AnnouncementsContainer = styled.div``

const CloseButton = styled.button`
  position: absolute;
  height: 3rem;
  width: 3rem;
  right: 0.25rem;
  z-index: 2; // Push the close button above the announcements with zIndex 1
  transform: scale(0.7);
  svg {
    path {
      stroke: black;
      stroke-width: 1.5;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    right: 2.5rem;
  }
`

const NormalWrapper = styled.div`
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  margin: 0 3rem;
  transition: opacity 0.7s cubic-bezier(0.02, 0.01, 0, 0.95);
  opacity: 0;
  z-index: 0;
  &.active {
    opacity: 1;
    z-index: 1;
    div {
      top: 0%;
    }
  }
  div {
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 100%;
    transition: top 0.7s cubic-bezier(0.02, 0.01, 0, 0.95);
    transition-delay: 0.85s;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 5.5rem;
  }
`
