import { FC } from "react"
import styled from "styled-components"
import Link from "next/link"
import { useRouter } from "next/router"

import { useUI, useDictionary, useCheckout, useCustomer, useTracking } from "@hooks/index"

import { routes } from "@lib/config"
import { MenuItem, MenuButton } from "@components/common"
import Search from "@svg/search.svg"

export const UserNav: FC = () => {
  const dictionary = useDictionary()
  const { openCart, openLoginModal } = useUI()

  const { lineItems } = useCheckout()
  const { customer, logoutCustomer } = useCustomer()
  const router = useRouter()
  const { track } = useTracking()

  const logout = async () => {
    if (router.route.includes("account")) {
      await router.push("/")
    }
    logoutCustomer()
  }

  const cartCount = lineItems?.edges
    ? lineItems.edges.reduce(
        (acc: number, val: { node: { quantity: number } }) => acc + val.node.quantity,
        0
      )
    : 0

  const openRewardsLauncher = () => {
    try {
      if (typeof window !== "undefined") {
        /*@ts-ignore*/
        window.StampedFn.toggleRewardsModal()
      }
    } catch (error) {}
  }

  return (
    <UserNavStyled>
      <MenuButton type="cart" onClick={openCart} />
      <Desktop>
        <SearchButton>
          <Link href={`/search`}>
            <StyledSearch />
          </Link>
        </SearchButton>
        <MenuItem>
          <button onClick={openRewardsLauncher}>Rewards</button>
        </MenuItem>
        {customer ? (
          <>
            <MenuItemWithSubmenu onMouseOver={() => track("Account Nav Hovered")}>
              {customer?.firstName || "account"}
            </MenuItemWithSubmenu>
            <AccountPanelContainer>
              <AccountPanel>
                <AccountPanelTitle>{dictionary.myAccount}</AccountPanelTitle>
                <AccountRoutes>
                  {routes.map(({ title, href }) => {
                    return (
                      <NavItem key={title} $isActive={href === router.route}>
                        <Link href={`/${href}`} passHref>
                          <a
                            onClick={() =>
                              track("Link Clicked", {
                                url: `https://www.hautehijab.com/${href}`,
                                label: title,
                              })
                            }
                          >
                            {title}
                          </a>
                        </Link>
                      </NavItem>
                    )
                  })}
                  <NavItem>
                    <button
                      onClick={() => {
                        logout()
                        track("Button Clicked", {
                          type: "Button",
                          category: "Menu level 1",
                          label: "Logout",
                        })
                      }}
                    >
                      Logout
                    </button>
                  </NavItem>
                </AccountRoutes>
              </AccountPanel>
            </AccountPanelContainer>
          </>
        ) : (
          <MenuItem>
            <button onClick={() => openLoginModal()}>Login</button>
          </MenuItem>
        )}
        <MenuItem>
          <button onClick={() => openCart()}>{`${dictionary.bag} (${cartCount})`}</button>
        </MenuItem>
      </Desktop>
    </UserNavStyled>
  )
}

const UserNavStyled = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1;
  height: 100%;
`

const Desktop = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: flex;
    height: 100%;
  }
`

const SearchButton = styled.button`
  position: relative;
  top: 0.3rem;
  flex: 1;
  padding: 0 1 rem;
  margin-right: 2.9rem;
  cursor: pointer;
`

const StyledSearch = styled(Search)`
  width: 2rem;
`

const AccountPanelContainer = styled.div`
  display: none;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-end;
  top: ${({ theme }) => theme.navbar.md.height}rem;
  left: 0;
  right: 0;
  max-width: 144rem;
  margin: 0 auto;
  height: 0;

  &:hover {
    display: flex;
  }
`

const MenuItemWithSubmenu = styled(MenuItem)`
  &:hover {
    + ${AccountPanelContainer} {
      display: flex;
    }
  }
`

const AccountPanel = styled.div`
  text-transform: capitalize;
  background: ${({ theme }) => theme.color.white};
  padding: 2.3rem 5.8rem 3rem 3rem;
  color: ${({ theme }) => theme.color.black};
  font-weight: 400;
  white-space: nowrap;
`

const AccountPanelTitle = styled.h1`
  font-weight: 300;
  font-size: 2rem;
`

const AccountRoutes = styled.ul``

const NavItem = styled.li<{ $isActive?: boolean }>`
  line-height: 2.8rem;
  font-size: 1.4rem;
  font-weight: ${({ $isActive }) => $isActive && 700};
  letter-spacing: 0;

  &:hover {
    text-decoration: underline;
  }

  button {
    font-family: ${({ theme }) => theme.font.primary};
    font-size: 1.4rem;

    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`
