import { FC, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useCustomer, useDictionary, useTracking } from '@hooks/index';
import * as api from '@lib/api'
import { Input, SubmitButton } from '@components/ui';
import { LoginCustomerResponse } from '@lib/account';
import { decode } from 'shopify-gid';

interface Props {
  showResetPassword: () => void;
  closeLoginModal: () => void;
  multipassCheckoutUrl?: string;
}

export const LoginForm: FC<Props> = ({
  showResetPassword,
  closeLoginModal,
  multipassCheckoutUrl,
}) => {
  const dictionary = useDictionary();
  const { loginCustomer, loading, setStampedRewards } = useCustomer();
  const { register, handleSubmit, errors, clearErrors } = useForm({ mode: 'onBlur' });
  const [error, setError] = useState('');
  const { identify, track } = useTracking();
  const onSubmit = async ({ email, password }: { email: string; password: string }) => {
    const response: LoginCustomerResponse = await loginCustomer({ email, password })

    if (response?.customerUserErrors?.length) {
      setError(response.customerUserErrors[0].message)
      return
    }
    if (response?.customer?.email && multipassCheckoutUrl && multipassCheckoutUrl !== '') {
      api.getMultipassUrl(response.customer.email, multipassCheckoutUrl).then(url => window.location.href = (url))
      return
    }
    
    track("Logged In", {
      userLogin: response?.customer?.id,
      email,
    })

    const authToken = await fetch("/api/getStampedAuthToken", {
      method: "POST",
      body: JSON.stringify({
        email: response?.customer?.email,
        userId: decode(response?.customer?.id).id
      })
    })

    const stampedAuthToken = await authToken.text();

    const loginObj = {
      customerId: decode(response?.customer?.id).id,
      customerEmail: response?.customer?.email,
      customerFirstName: response?.customer?.firstName,
      customerLastName: response?.customer?.lastName,
      customerTags: '',
      totalOrders: response?.customer?.orders?.edges.length,
      totalSpent: response?.customer?.orders?.edges?.map(edge => Number(edge.node.totalPriceV2.amount))?.reduce((a, b) => a + b, 0),
      isAcceptMarketing: response?.customer?.acceptsMarketing,
      authToken: stampedAuthToken
    }

    setStampedRewards(loginObj);

    setError('');
    closeLoginModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="email"
        ref={register({
          required: 'Email is required',
          pattern: {
            value: /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/,
            message: 'Please enter a valid email',
          },
        })}
        type="email"
        placeholder="Email address"
        errors={errors}
        clearErrors={clearErrors}
      />
      <Input
        name="password"
        ref={register({ required: 'Password is required' })}
        type="password"
        placeholder="Password"
        errors={errors}
        clearErrors={clearErrors}
      />
      <LinkWrapper>
        <InlineLink onClick={() => showResetPassword()}>{dictionary.forgotYourPassword}</InlineLink>
      </LinkWrapper>
      <SubmitButton title="Log in" loading={loading} error={error} />
    </form>
  );
};

const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const InlineLink = styled.span`
  font-size: 1.3rem;
  line-height: 3rem;
  text-decoration: underline;
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;
`;
